body {
  overflow: hidden;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

#stream-buffer {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#canvas-container {
  overflow: hidden;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  max-width: 600px;
}
@media only screen and (min-width: 852px) {
  #canvas-container {
    max-height: 600px;
  }
}
#stop {
  position: absolute;
  bottom: 5px;
  border: none;
  background-color: white;
  border-radius: 5px;
  height: 30px;
  width: 50px;
  background-color: transparent;
  color: white;
  border: 1px solid white;
}
.profile-info {
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(28, 28, 28, 0.28);
  padding: 15px;
  width: 100px;
  color: white;
}
.chip {
  position: absolute;
  left: 50%;
  bottom: 15px;
  transform: translateX(-50%);
  background-color: yellow;
  color: black;
  max-width: 200px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  padding: 7px;
  overflow: hidden;
  z-index: 10;
  font-size: 0.7rem;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.5);
  transition: opacity 500ms ease;
}
.chip.hide {
  opacity: 0;
}
.chip.show {
  opacity: 1;
}
.chip a {
  color: black!important;
  font-weight: 500;
}
.chip p {
  margin: 0;
}
